import http from '@/services/http';
import apiEndpoints from '@/services/apiEndpoints';
import router from '@/router';
import { get } from 'lodash';

const getDefaultState = () => {
  return {};
};

const state = getDefaultState();
const getters = {};
const mutations = {};
const actions = {
  async getAvailability () {
    return await http.get(apiEndpoints.maintenances_availability);
  },
  async checkDuringMaintain () {
    const resMaintain = await this.dispatch('maintenanceStore/getAvailability');
    const { status, data } = resMaintain;
    if (status && data.result && data.result.id) {
      return true;
    }
    return false;
  },
  async checkDuringMaintainAndRedirecToMaintain () {
    const resMaintain = await this.dispatch('maintenanceStore/checkDuringMaintain');
    if (resMaintain) {
      const currentRoute = get(router, 'history.router.history.current.name');
      if (!(currentRoute === 'maintain')) {
        router.push({
          name: 'maintain',
        });
      }
    }
    return false;
  },
};
export default {
  state,
  mutations,
  actions,
  getters,
};
