<template>
  <nav
    id="sidebar"
    class="sidebar"
    :class="isShowSidebar ? 'show' : 'collapsed'"
  >
    <div class="sidebar-content">
      <div class="sidebar-direction">
        <v-btn icon class="btn-back">
          <svg-icon icon="back" @click="closeSidebar"></svg-icon>
        </v-btn>
      </div>
      <v-list>
        <v-list-item v-for="(item, index) in sidebarMenuItems" :key="index">
          <div class="sidebar-link" @click="redirectTo(item.router_name)">
            <svg-icon :icon="item.icon"></svg-icon>
            <span class="align-middle">{{ item.name }}</span>
          </div>
        </v-list-item>
      </v-list>
      <div class="sidebar-action">
        <v-btn outlined class="btn-logout" @click="logout">
          <svg-icon icon="logout"></svg-icon>
          <span>{{ $t('common.button.logout') }}</span>
        </v-btn>
      </div>
    </div>
    <div
      @click.prevent="closeSidebar"
     class="sidebar-backdrop" :class="{ show: isShowSidebar }"></div>
  </nav>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'UserSidebar',
  props: {
    isShowSidebar: { type: Boolean, default: false },
  },
  data () {
    return {
      status: true,
      sidebarMenuItems: [
        {
          icon: 'credit_card',
          router_name: 'card_input',
          path: 'card-input',
          name: this.$t('common.sidebar.credit_card'),
        },
        {
          icon: 'history',
          router_name: 'transaction_list',
          path: '',
          name: this.$t('common.sidebar.transaction_history'),
        },
      ],
    };
  },
  methods: {
    async logout () {
      await this.dispatchLogout();
      this.$router.push({ name: 'login' });
    },
    closeSidebar () {
      this.$emit('closeSidebar');
    },
    redirectTo (routerName) {
      this.closeSidebar();
      this.$router.push({ name: routerName });
    },
    ...mapActions({
      dispatchLogout: 'userStore/logout',
    }),
  },
};
</script>
