<template>
  <v-menu
    ref="menuDate"
    v-model="menuDate"
    :return-value="date"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    content-class="elevation-1 custom-width-menu"
    :disabled="isDisable"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="date | formatDate"
        autocomplete="off"
        dense
        v-on="on"
        v-bind="attrs"
        :outlined="isOutlined"
        hide-details
        :placeholder="placeholder"
        readonly
        :clearable="clearable"
        :label="label"
        class="v-input--datepicker"
        :class="classList"
        :disabled="isDisable"
        @click:clear="doClear"
      >
        <template slot="append">
          <svg-icon icon="calendar" @click="menuDate = true"></svg-icon>
        </template>
      </v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      no-title
      locale="ja-jp"
      color="#00bcb5"
      :min="min"
      :max="max"
      :reactive="true"
      :day-format="(date) => new Date(date).getDate()"
      @change="save"
      @input="onInputDate"
      :disabled="isDisable"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import moment from 'moment';
const DATE_FORMAT = 'YYYY/MM/DD';

export default {
  name: 'DatePicker',
  props: {
    label: { type: String, default: null },
    min: { type: String, default: null },
    max: { type: String, default: null },
    isDisable: { type: Boolean, default: false },
    dateFormat: { type: String, default: 'YYYY/MM/DD' },
    classList: { type: [String, Object], default: '' },
    isOutlined: { type: Boolean, default: true },
    valueInit: { type: String, default: null },
    placeholder: { type: String, default: 'YYYY/MM/DD' },
    clearable: { type: Boolean, default: false },
  },
  data () {
    return {
      menuDate: '',
      date: null,
    };
  },
  created () {
    if (this.valueInit) {
      this.date = this.valueInit;
    }
  },
  methods: {
    onInputDate () {
      this.$emit('input', this.date);
    },
    doClear () {
      this.date = null;
      this.$emit('input', this.date);
      this.$emit('setClearDate');
    },
    save (date) {
      this.$refs.menuDate.save(date);
      this.$emit('input', this.date);
    },
  },
  watch: {
    valueInit (newVal) {
      // this.date = this.$commonHelper.formatDate(newVal);
      this.date = newVal;
    },
  },
  filters: {
    formatDate (value) {
      if (value) {
        return moment(value).format(DATE_FORMAT);
      }
    },
  },
};
</script>
<style scoped>
  .custom-width-menu {
    min-width: auto !important;
  }
</style>
