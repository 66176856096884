import Vue from 'vue';
import VueI18n from 'vue-i18n';
import enLangCommon from './en/common';
import enValidate from './en/validate';

import jaLangCommon from './ja/common';
import jaAuth from './ja/auth';
import jaValidate from './ja/validate';
import jaHome from './ja/home';
import jaCard from './ja/card';
import jaPoint from './ja/point';
import jaTransaction from './ja/transaction';
Vue.use(VueI18n);

const langs = {
  en: {
    common: enLangCommon,
    validate: enValidate,
  },
  ja: {
    common: jaLangCommon,
    validate: jaValidate,
    auth: jaAuth,
    home: jaHome,
    card: jaCard,
    point: jaPoint,
    transaction: jaTransaction,
  },
};

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'ja',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'ja',
  messages: langs,
  silentFallbackWarn: true,
});
