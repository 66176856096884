const companyConstant = {
  add_point_feature_flag_true: 1,
  add_point_feature_flag_false: 0,
  use_point_feature_flag_true: 1,
  use_point_feature_flag_false: 0,
  ticket_feature_flag_true: 1,
  ticket_feature_flag_false: 0,
};

export default companyConstant;
