const transactionConstant = {
  ADD_POINT: 'ADD_POINT',
  CANCEL_ADD_POINT: 'CANCEL_ADD_POINT',
  USE_POINT: 'USE_POINT',
  CANCEL_USE_POINT: 'CANCEL_USE_POINT',
  TYPE_TO_DISPLAY_AMOUNT: [
    'ADD_POINT', 'CANCEL_ADD_POINT',
  ],
  STATUS_INITIAL: 'INITIAL',
  STATUS_SYNCED: 'SYNCED',
  STATUS_CANCELED: 'CANCELED',
  STATUS_IGNORED: 'IGNORED',

  limit: {
    MIN_AMOUNT: 0,
    MAX_AMOUNT: 99999999,
    MIN_TOTAL_ADD_POINT: 1,
  },
};

export default transactionConstant;
