const prefixUserEndpoint = '/user';

export default {
  login: prefixUserEndpoint + '/login',
  logout: prefixUserEndpoint + '/logout',
  me: prefixUserEndpoint + '/me',
  promotions: prefixUserEndpoint + '/promotions',
  maintenances: prefixUserEndpoint + '/maintenances',
  transactions: prefixUserEndpoint + '/transactions',
  card_balance: prefixUserEndpoint + '/cards/{card_number}/balance',
  create_transaction: prefixUserEndpoint + '/transactions',
  transactions_history: prefixUserEndpoint + '/transactions/history',
  transaction: prefixUserEndpoint + '/transactions/:id',
  use_point_ticket: prefixUserEndpoint + '/use_point_ticket',
  store_promotions: prefixUserEndpoint + '/store/promotions',
  check_store_point_limit: prefixUserEndpoint + '/transactions/store/check-point-limit',
  cancel_transaction: prefixUserEndpoint + '/transactions/:id/cancel',
  check_promotion: prefixUserEndpoint + '/promotions/check-by-code',
  maintenances_availability: prefixUserEndpoint + '/maintenances/availability',
  update_add_point_transaction: prefixUserEndpoint + '/transactions/add-point/:id/update',
  update_use_point_transaction: prefixUserEndpoint + '/transactions/use-point/:id/update',
  taxes: prefixUserEndpoint + '/taxes',
  update_activity: prefixUserEndpoint + '/activity',
  get_promotion_by_barcode: prefixUserEndpoint + '/promotions/barcode/{barcode_number}',
  get_card_number: prefixUserEndpoint + '/cards/get-card-number',
};
