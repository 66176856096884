<template>
  <v-dialog v-model="dialog" width="auto" fullscreen persistent>
    <div class="qrcode-container">
      <div class="qrcode-wrapper">
        <div class="qrcode-body">
          <v-snackbar
            v-model="snackbar"
            :timeout="-1"
            class="v-snack--error"
            top
            right
          >
            {{ errorMessage }}
          </v-snackbar>
          <qrcode-stream
            v-if="isShowCamera"
            :camera="camera"
            @decode="onDecode"
            @init="onInit"
          />
          <div class="qrcode-label" v-if="qrCodeResult">{{ qrCodeResult }}</div>
          <div class="camera-focus">
            <div class="camera-focus-area">
              <div class="camera-focus-corner"></div>
              <div class="camera-focus-corner"></div>
            </div>
          </div>
        </div>
        <div class="qrcode-footer">
          <div class="group-button">
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-btn
                    depressed
                    outlined
                    @click="close"
                    >{{ $t('common.button.back') }}</v-btn
                  >

                </v-col>
                <v-col cols="6">
                  <v-btn
                    class="success"
                    color="success"
                    depressed
                    @click="onClickRetry"
                    >{{ $t('card.button.retry') }}</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </div>
        </div>
      </div>
    </div>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import md from '@/services/mobileDetect';
import * as apiResponse from '@/configs/apiResponse';
import CardService from '@/services/cardService';

const TYPE_CARD = 'card';
const VALIDATE_OPTION_SPEC = 'spec';
const VALIDATE_OPTION_BALANCE = 'balance';

export default {
  name: 'DialogQRcodeScan',
  props: {
    type: {
      type: String,
      default: TYPE_CARD,
    },
    validateOptions: {
      type: Array,
      default: function () {
        return [VALIDATE_OPTION_SPEC, VALIDATE_OPTION_BALANCE];
      },
    },
  },
  data () {
    return {
      snackbar: false,
      isDone: false,
      dialog: false,
      cameraConfig: {
        deviceId: '',
        isHasPermission: false,
        isTurnOnTorch: false,
      },
      camera: 'auto',
      errorMessage: null,
      windowHeight: window.innerHeight,
      isIOS: false,
      qrCodeResult: '',
      balance: {},
      isShowCamera: false,
      error: '',
    };
  },
  watch: {

  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.onResize);
  },
  async created () {
    this.isIOS = md.isIOS();
    this.isShowCamera = true;
  },
  methods: {
    ...mapActions({
      addError: 'snackbar/addError',
      getBalance: 'cardStore/getBalance',
    }),
    goToTransactionMenu (data) {
      this.$router.push({
        name: 'transaction_menu',
        params: {
          card_number: this.qrCodeResult,
          balance: this.balance,
        },
      });
    },
    async processTypeCard () {
      if (this.validateOptions.length === 0) {
        this.emitResult();
      }

      // config validateOptions: ['spec']
      if (this.validateOptions.indexOf(VALIDATE_OPTION_SPEC) !== -1) {
        const cardServiceInstance = new CardService();
        cardServiceInstance.setCardSpecs(this.user.store.company.client.cards);
        const validateResult = cardServiceInstance.validateQRcode(this.qrCodeResult);
        this.qrCodeResult = cardServiceInstance.getCardNumberTrimmed();
        // validateOptions only spec
        if (this.validateOptions.length === 1) {
          if (validateResult) {
            this.isDone = true;
            this.goToTransactionMenu();
          } else {
            this.showInvalidateMessage();
          }
        } else if (this.validateOptions.indexOf(VALIDATE_OPTION_BALANCE) !== -1) {
          if (!validateResult) {
            this.showInvalidateMessage();
          } else if (this.systemHasApi) {
            // api check balance
            const resBalance = await this.getBalance(this.qrCodeResult);
            const { code, data, status } = resBalance;
            let isMove = true;
            if (code === apiResponse.ERROR_CODE_NOT_FOUND) {
              this.showInvalidateMessage();
              isMove = false;
            }

            if (status) {
              const { status: finalCardStatus, error_message: errorMessage, balance } = data.result;
              this.balance = balance;
              if (finalCardStatus === this.$constant.card_spec.FINAL_STATUS_INVALID) {
                this.showInvalidateMessage(errorMessage);
                isMove = false;
              }
            }
            // To do : case offline, case timeout
            if (isMove) {
              this.isDone = true;
              this.goToTransactionMenu();
            }
          } else {
            this.isDone = true;
            this.goToTransactionMenu();
          }
        }
      }
    },
    async onDecode (content) {
      if (content) {
        this.qrCodeResult = content.replace(/[\D]/g, '');
        this.turnCameraOff();
        await this.processTypeCard();
      }
    },
    turnCameraOn () {
      this.camera = 'auto';
    },
    turnCameraOff () {
      this.camera = 'off';
    },
    async onInit (promise) {
      try {
        await promise;
      } catch (error) {
        console.log('ERROR', error);
      }
    },
    onClickRetry () {
      this.initValue();
      this.turnCameraOn();
    },
    initValue () {
      this.isShowCamera = true;
      this.qrCodeResult = null;
      this.snackbar = false;
      this.errorMessage = '';
    },
    showInvalidateMessage (errorMessage = '') {
      if (errorMessage === '') {
        this.errorMessage = this.$t('common.message.invalid', { itemName: this.$t('card.label.card_no') });
      } else {
        this.errorMessage = errorMessage;
      }
      this.snackbar = true;
    },
    getCameraId () {
      return new Promise((resolve) => {
        navigator.mediaDevices
          .enumerateDevices()
          .then(function (deviceInfos) {
            const videoInputs = deviceInfos.filter(
              (d) => d.kind === 'videoinput',
            );
            if (videoInputs.length > 0) {
              const highQualityCamera = videoInputs.filter(
                (v) =>
                  v.label.indexOf('back') !== -1 &&
                    v.label.indexOf('2 0') !== -1,
              );
              resolve(
                highQualityCamera.length > 0
                  ? highQualityCamera[0].deviceId
                  : videoInputs[videoInputs.length - 1].deviceId,
              );
            } else {
              resolve();
            }
          });
      });
    },
    askCameraPermission () {
      return new Promise((resolve) => {
        navigator.mediaDevices
          .getUserMedia({ audio: false, video: true })
          .then(function () {
            return resolve(true);
          })
          .catch(function () {
            return resolve(false);
          });
      });
    },
    async requestCameraFirstTime () {
      const promptCamera = await this.askCameraPermission();
      if (promptCamera) {
        // this.addError(this.$t('card.errors.reload_and_scan'));
        this.showQrCodeReader();
      }
    },
    async show () {
      await this.showQrCodeReader();
    },

    async showQrCodeReader () {
      if (!this.cameraConfig.deviceId) {
        this.cameraConfig.deviceId = await this.getCameraId();
      }
      // await this.initQrCodeReader();
      this.turnCameraOn();
    },
    getUniqueListBy (arr, key) {
      return [...new Map(arr.map((item) => [item[key], item])).values()];
    },

    async open (data = null) {
      this.dialog = true;
      this.initData = data;
      this.initValue();
      if (this.isIOS) {
        this.showQrCodeReader();
      } else {
        const camPermission = await this.checkCameraPermission();
        switch (camPermission) {
          case 'denied':
            this.cameraConfig.isHasPermission = false;
            break;

          case 'prompt':
            await this.requestCameraFirstTime();
            break;

          // case granted
          default:
            this.showQrCodeReader();
            break;
        }
      }
    },
    async checkCameraPermission () {
      return new Promise((resolve) => {
        if (navigator.permissions && navigator.permissions.query) {
          navigator.permissions.query({ name: 'camera' }).then(async (res) => {
            return resolve(res.state);
          });
        }
      });
    },
    close () {
      this.turnCameraOff();
      this.isShowCamera = false;
      this.$emit('close');
      this.dialog = false;
    },
    destroyed () {
      this.turnCameraOff();
    },
  },
  computed: {
    ...mapGetters({
      user: 'userStore/user',
    }),
    systemHasApi () {
      if (this.user && this.user.store) {
        return this.user.store.company.client.system.api_available === this.$constant.system.API_AVAILABLE;
      }
      return false;
    },
    cameraContentStyle () {
      const footerHeight = 92;
      const contentHeight = window.innerHeight - footerHeight;
      return {
        height: (contentHeight || 0) + 'px !important',
      };
    },
    cameraFocusAreaStyle () {
      const innerHeight = window.innerHeight;
      return {
        borderTopWidth: ((innerHeight - 380) / 2) + 'px !important',
        borderBottomWidth: ((innerHeight - 380) / 2) + 'px !important',
      };
    },
  },
  onResize () {
    this.windowHeight = window.innerHeight;
  },
};
</script>
<style scoped>
.qrcode-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
}

.qrcode-body {
  flex: 1;
  background: #000;
}

.qrcode-footer {
  z-index: 90;
}

.qrcode-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.group-button {
  background: #1d1d1d;
  padding: 8px;
}

.camera-focus {
  position: fixed;
  top: 50%;
  left: 50%;
}
.camera-focus-area {
  box-sizing: content-box;
  position: relative;
  width: 280px;
  height: 280px;
  border-color: #56514b85;
  border-style: solid;
  border-top-width: calc((100vh - 280px - 92px) / 2);
  border-bottom-width: calc((100vh - 280px - 92px) / 2);
  border-right-width:  calc((100vw - 280px) / 2);
  border-left-width:  calc((100vw - 280px) / 2);
}

/* .camera-focus-area:before,
.camera-focus-area:after, */
.camera-focus-area .camera-focus-corner:first-child:before,
.camera-focus-area .camera-focus-corner:first-child:after,
.camera-focus-area .camera-focus-corner:nth-child(2):before,
.camera-focus-area .camera-focus-corner:nth-child(2):after {
  position: absolute;
  width: 28px;
  height: 28px;
  content: '';
  background-size: 100% 100%;
}

.camera-focus-area .camera-focus-corner:first-child:before {
  top: 0;
  left: 0;
  background-image: url(@/assets/icons/ico_corner_top_left.svg);
}

.camera-focus-area .camera-focus-corner:first-child:after {
  top: 0;
  right: 0;
  background-image: url(@/assets/icons/ico_corner_top_right.svg);
}

.camera-focus-area .camera-focus-corner:nth-child(2):before {
  bottom: 0;
  left: 0;
  background-image: url(@/assets/icons/ico_corner_bottom_left.svg);
}

.camera-focus-area .camera-focus-corner:nth-child(2):after {
  bottom: 0;
  right: 0;
  background-image: url(@/assets/icons/ico_corner_bottom_right.svg);
}

.camera-content-video {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 2;
}

.qrcode-label {
  position: fixed;
  left: 50%;
  top: calc(50vh + 120px);
  transform: translateX(-50%);
  height: auto;
  background: #1d1d1d;
  min-width: 195px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border-radius: 12px;
  color: #fff;
  font-size: 17px;
  z-index: 999;
}

  /* camera-focus-area */
.camera-focus {
  flex-grow: 1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 90px;
}

.camera-focus-area {
  box-sizing: content-box;
  position: relative;
  width: 280px;
  height: 280px;
  border-color: #56514b85;
  border-style: solid;
  border-top-width: calc((100vh - 280px - 92px) / 2);
  border-bottom-width: calc((100vh - 280px - 92px) / 2);
  border-right-width:  calc((100vw - 280px) / 2);
  border-left-width:  calc((100vw - 280px) / 2);
}

/* .camera-focus-area:before,
.camera-focus-area:after, */
.camera-focus-area .camera-focus-corner:first-child:before,
.camera-focus-area .camera-focus-corner:first-child:after,
.camera-focus-area .camera-focus-corner:nth-child(2):before,
.camera-focus-area .camera-focus-corner:nth-child(2):after {
  position: absolute;
  width: 28px;
  height: 28px;
  content: '';
  background-size: 100% 100%;
}

.camera-focus-area .camera-focus-corner:first-child:before {
  top: 0;
  left: 0;
  background-image: url(@/assets/icons/ico_corner_top_left.svg);
}

.camera-focus-area .camera-focus-corner:first-child:after {
  top: 0;
  right: 0;
  background-image: url(@/assets/icons/ico_corner_top_right.svg);
}

.camera-focus-area .camera-focus-corner:nth-child(2):before {
  bottom: 0;
  left: 0;
  background-image: url(@/assets/icons/ico_corner_bottom_left.svg);
}

.camera-focus-area .camera-focus-corner:nth-child(2):after {
  bottom: 0;
  right: 0;
  background-image: url(@/assets/icons/ico_corner_bottom_right.svg);
}
</style>
