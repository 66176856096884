export default {
  button: {
    start_transaction: '取引開始',
    use_point_ticket: 'ポイント券利用',
    search_transaction_history: '取引履歴検索',
  },
  label: {
    notification: 'おしらせ',
    today_plan: '本日の企画',
    maintenance: 'メンテナンス体制',
  },
  message: {
    no_promotion: '現在、プロモーションはありません。',
    no_maintenance: '現在、お知らせはありません。',
  },
};
