<template>
  <v-app>
    <loading-screen v-if="isLoading" :isLoading="isLoading"></loading-screen>
    <component :is="layout" v-else>
      <router-view v-if="!isLoading" :key="$route.fullPath" />
    </component>
    <snack-bar></snack-bar>
    <progress-circular v-if="isShowLoading"></progress-circular>
    <user-dialog ref="dialog_confirm" :classList="['maxw-500']">
      <template>
        <p class="mb-0" v-html="$t('common.message.no_connection')"></p>
      </template>
    </user-dialog>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
const DEFAULT_LAYOUT = 'blank';
export default {
  data () {
    return {
      isLoading: true,
    };
  },
  created () {
    window.addEventListener('resize', this.resizeEventHandler);
    window.addEventListener('online', this.updateConnectionStatus);
    window.addEventListener('offline', this.updateConnectionStatus);

    this.resizeEventHandler();
    this.updateConnectionStatus();
  },
  async mounted () {
    const isDuringMaintain = await this.checkDuringMaintain();
    if (isDuringMaintain) {
      this.$router.push({ name: 'maintain' });
    } else if (window.location.pathname === '/maintain') {
      this.$router.push({ name: 'home' });
    }
    setTimeout(() => {
      this.isLoading = false;
    }, 100);
  },
  methods: {
    ...mapActions({
      setIsMobileSize: 'setIsMobileSize',
      setIsOnline: 'setIsOnline',
      checkDuringMaintain: 'maintenanceStore/checkDuringMaintain',
    }),
    resizeEventHandler (e) {
      this.setIsMobileSize(window.innerWidth < this.$constant.breakpoint_mobile);
    },
    updateConnectionStatus () {
      this.setIsOnline(navigator.onLine);
    },
    handleInternetConnection (currentStatus, oldStatus) {
      if (currentStatus && oldStatus === false) {
        this.closeNoConnectionDialog();
        this.displayMessageConnectionRecovered();
        return;
      }
      this.openNoConnectionDialog();
    },
    displayMessageConnectionRecovered () {
      this.$store.dispatch('snackbar/addSuccess', this.$t('common.message.connection_recovered'));
    },
    openNoConnectionDialog () {
      this.$refs.dialog_confirm.open();
    },
    closeNoConnectionDialog () {
      this.$refs.dialog_confirm.close();
    },
  },
  computed: {
    ...mapGetters({
      isShowLoading: 'isShowLoading',
      isMobileSize: 'isMobileSize',
      isOnline: 'isOnline',
    }),
    layout () {
      return (this.$route.meta.layout || DEFAULT_LAYOUT) + '-layout';
    },
  },
  destroyed () {
    window.removeEventListener('resize', this.resizeEventHandler);
    window.removeEventListener('online', this.resizeEventHandler);
    window.removeEventListener('offline', this.resizeEventHandler);
  },
  watch: {
    isOnline: {
      handler: function (newVal, oldVal) {
        this.handleInternetConnection(newVal, oldVal);
      },
    },
  },
};
</script>
