<template>
  <div>
    <nav class="navbar navbar-expand navbar-light navbar-bg d-block d-md-none">
      <div class="navbar-wrapper">
        <img class="logo" src="@/assets/images/img_logo.png" />

        <div class="text-right d-flex justify-end align-center fill-width">
          <v-btn icon class="btn-menu" @click="onToggleSidebar">
            <svg-icon icon="menu"></svg-icon>
          </v-btn>
        </div>

        <user-sidebar
          @closeSidebar="onToggleSidebar"
          :isShowSidebar="isShowSidebar"
        ></user-sidebar>
      </div>
    </nav>
    <nav class="header-desktop">
      <div class="header-wrapper">
        <div class="d-flex justify-start">
          <div class="header-logo">
            <img class="logo" src="@/assets/images/img_logo.png" />
          </div>
          <div class="header-nav">
            <router-link
              class="header-nav__item"
              v-for="(item, index) in headerItems"
              :key="index"
              :to="{ name: item.router_name }"
              :class="{
                'header-nav__item--active': $route.name === item.router_name,
              }"
              >{{ item.name }}</router-link
            >
          </div>
        </div>
        <div class="header-dropdown">
          <v-menu bottom right offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn plain text v-bind="attrs" v-on="on" class="btn-dropdown">
                <span class="mr-3">{{ authUserName }}</span>
                <svg-icon icon="triagle_down"></svg-icon>
              </v-btn>
            </template>
            <div class="header-dropdown-content">
              <v-btn text small class="btn-logout" @click="logout">
                <svg-icon icon="logout"></svg-icon>
                <span class="ml-3">{{ $t('common.button.logout') }}</span>
              </v-btn>
            </div>
          </v-menu>
        </div>
      </div>
    </nav>

    <!-- dialog 5 minute before timeout -->
    <user-dialog ref="dialog_before_timeout" :classList="['maxw-500']">
      <template slot="header">{{ $t('common.timeout.before.title') }}</template>
      <template>
        <p class="mb-0 text-left">{{ $t('common.timeout.before.msg1') }}</p>
        <p class="mb-0 text-left">{{ $t('common.timeout.before.msg2') }}</p>
      </template>
      <template slot="footer">
        <v-row>
          <v-col cols="12">
            <v-btn
              outlined
              color="secondary"
              class="fill-width"
              @click="onClickReActive()"
            >
              {{ $t('common.button.ok') }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </user-dialog>

    <!-- dialog timeout-ed -->
    <user-dialog ref="dialog_timeout" :classList="['maxw-500']">
      <template>
        <p class="mb-0 text-left">{{ $t('common.timeout.outed.msg1') }}</p>
        <p class="mb-0 text-left">{{ $t('common.timeout.outed.msg2') }}</p>
      </template>
      <template slot="footer">
        <v-row>
          <v-col cols="12">
            <v-btn
              outlined
              color="secondary"
              class="fill-width"
              @click="onRedirectToLogin()"
            >
              {{ $t('common.button.ok') }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </user-dialog>
  </div>
</template>

<script>
import UserSidebar from '@/layouts/user/Sidebar.vue';
import { mapActions, mapGetters } from 'vuex';
import { debounce } from 'lodash';
const timeDelay = process.env.VUE_APP_ACTIVITY_DELAY || 30;

export default {
  name: 'UserHeader',
  components: {
    UserSidebar,
  },
  data () {
    return {
      isShowSidebar: false,
      headerItems: [
        {
          router_name: 'home',
          name: this.$t('common.link.home'),
        },
        {
          router_name: 'card_input',
          name: this.$t('common.link.start_transaction'),
        },
        {
          router_name: 'transaction_list',
          name: this.$t('common.link.search_transaction_history'),
        },
      ],
      isShowTimeoutNoticeBefore: false,
      isShowTimeoutNotice: false,
      timeDelay: process.env.VUE_APP_ACTIVITY_DELAY || 30,
    };
  },
  created () {
    this.dispatchUserActivity();

    document.addEventListener('mousemove', this.debounceActivity);

    setInterval(function () {
      if (this.lastTimeActive) {
        const unixNow = this.$moment().unix();
        const unixLastTimeActive = this.$moment(this.lastTimeActive).unix();
        const timeOut = process.env.VUE_APP_ACTIVITY_TIMEOUT || 240;
        const timeNotice = process.env.VUE_APP_ACTIVITY_NOTICE || 5;
        // before 5 minutes
        if (unixLastTimeActive + (timeOut * 60 - timeNotice * 60) <= unixNow && unixNow < unixLastTimeActive + timeOut * 60) {
          if (!this.isShowTimeoutNoticeBefore) {
            this.showBeforeTimeOutPopup();
          }
        } else if (unixNow >= unixLastTimeActive + timeOut * 60) {
          if (!this.isShowTimeoutNotice) {
            this.showTimeOutPopup();
          }
        } else {
          this.isShowTimeoutNoticeBefore = false;
          this.isShowTimeoutNotice = false;
        }
      }
    }.bind(this), 60000);
  },
  methods: {
    debounceActivity: debounce(function () {
      if (this.authUserName) {
        this.dispatchUserActivity();
      }
    }, timeDelay, { leading: true, trailing: false, maxWait: timeDelay }),

    onClickReActive () {
      this.dispatchUserActivity();
      if (this.$refs.dialog_before_timeout) {
        this.$refs.dialog_before_timeout.close();
      }
    },
    onRedirectToLogin () {
      if (this.$refs.dialog_timeout) {
        this.$refs.dialog_timeout.close();
      }
      this.$store.commit('userStore/UNSET_USER');
      this.$router.push({ name: 'login' });
    },
    showBeforeTimeOutPopup  () {
      this.isShowTimeoutNoticeBefore = true;
      this.isShowTimeoutNotice = false;
      if (this.$refs.dialog_timeout) {
        this.$refs.dialog_timeout.close();
      }
      if (this.$refs.dialog_before_timeout) {
        this.$refs.dialog_before_timeout.open();
      }
    },
    showTimeOutPopup  () {
      this.isShowTimeoutNoticeBefore = false;
      this.isShowTimeoutNotice = true;
      if (this.$refs.dialog_before_timeout) {
        this.$refs.dialog_before_timeout.close();
      }
      if (this.$refs.dialog_timeout) {
        this.$refs.dialog_timeout.open();
      }
    },
    async logout () {
      await this.dispatchLogout();
      this.$router.push({ name: 'login' });
    },
    onToggleSidebar () {
      this.isShowSidebar = !this.isShowSidebar;
    },
    ...mapActions({
      dispatchLogout: 'userStore/logout',
      dispatchUserActivity: 'userStore/updateActivity',
    }),
  },
  computed: {
    ...mapGetters({
      authUserName: 'authUserName',
      lastTimeActive: 'lastTimeActive',
    }),
  },
  destroyed () {
    window.removeEventListener('mousemove', this.debounceActivity);
  },

};
</script>
