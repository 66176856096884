<template>
  <div
    class="section-box"
    :class="classList"
    :style="{
      background: background
    }"
  >
    <slot/>
  </div>
</template>
<script>
export default {
  name: 'SectionBox',
  props: {
    padding: {
      type: Number, default: 0,
    },
    background: {
      type: String, default: '#fff',
    },
    classList: {
      type: String, default: '',
    },
  },
};
</script>
