<template>
  <div class="top-breadcrumb">
    <v-btn icon text plain :to="{ name: 'home' }" class="btn-home">
      <svg-icon icon="home"></svg-icon>
    </v-btn>
    <span>{{ title }}</span>
  </div>
</template>
<script>
export default {
  name: 'TopBreadcrumb',
  props: { title: { type: String, required: true } },
};
</script>
<style lang="scss">
.top-breadcrumb {
  display: flex;
  align-items: center;
  margin-left: -10px;
  margin-bottom: 10px;

  .btn-home {
    position: relative;
    margin-right: 25px;
    &:after {
      content: '»';
      position: absolute;
      left: 40px;
      display: block;
    }
  }
}

</style>
