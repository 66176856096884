const companyConstant = {
  type_add: 'ADD',
  type_multiply: 'MULTIPLY',
  target_type_company: 'COMPANY',
  target_type_store: 'STORE',
  limit: {
    MIN_ADD: 1,
    MAX_ADD: 99999,
    MIN_MULTIPLY: 2,
    MAX_MULTIPLY: 99,
  },
};

export default companyConstant;
