<template>
  <div v-if="isLoading">
    <progress-circular></progress-circular>
  </div>
</template>

<script>
export default {
  name: 'LoadingScreen',
  props: ['isLoading'],
};
</script>
