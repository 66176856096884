<template>
  <div class="user-pagination">
    <div
      class="v-pagination__navigation v-pagination__navigation--first"
      :disabled="isFirstDisabled"
      @click="currentPage = 1"
    >
      <v-icon
        large
        color="#666"
      >
        mdi-chevron-double-left
      </v-icon>
    </div>
    <v-pagination
      v-model="currentPage"
      :value="value"
      :length="length"
      :total-visible="totalVisible"
    ></v-pagination>
    <button
      class="v-pagination__navigation v-pagination__navigation--last"
      :disabled="isLastDisabled"
      @click="currentPage = length"
    >
      <v-icon
        large
        color="#666"
      >
        mdi-chevron-double-right
      </v-icon>
    </button>
  </div>
</template>

<script>
// const DEFAULT_TOTAL_VISIBLE = 7;

export default {
  name: 'AdminPagination',
  props: {
    value: { type: Number, default: 0 },
    disabled: { type: Boolean, default: false },
    length: { type: Number, default: 0 },
    totalVisible: { type: Number, default: 0 },
  },
  created () {
    this.currentPage = this.value;
  },
  data () {
    return {
      currentPage: 0,
    };
  },
  computed: {
    isFirstDisabled () {
      return this.currentPage <= 1;
    },
    isLastDisabled () {
      return this.currentPage >= this.length;
    },
  },
  watch: {
    currentPage: {
      handler (newVal) {
        this.$emit('input', this.currentPage);
      },
    },
    value: {
      handler (newVal) {
        this.currentPage = newVal;
      },
    },
  },
};
</script>
<style lang="scss">

</style>
