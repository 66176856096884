import http from '@/services/http';
import apiEndpoints from '@/services/apiEndpoints';
import moment from 'moment-timezone';

const getDefaultState = () => {
  return {
    user: {},
    token: '',
    canAddPoint: false,
    canUsePoint: false,
    lastTimeActive: null,
  };
};

const state = getDefaultState();
const getters = {
  user (state) {
    return state.user;
  },
  token (state) {
    return state.token;
  },
  canAddPoint (state) {
    return state.canAddPoint;
  },
  canUsePoint (state) {
    return state.canUsePoint;
  },
  lastTimeActive () {
    return state.lastTimeActive;
  },
};
const mutations = {
  SET_AUTH (state, { token, user }) {
    state.token = token;
    state.user = user;
  },
  SET_AUTH_USER (state, { user }) {
    state.user = user;
  },
  UNSET_USER (state) {
    state.token = '';
    state.user = {};
  },
  SET_CAN_ADD_POINT (state, payload) {
    state.canAddPoint = payload;
  },
  SET_CAN_USE_POINT (state, payload) {
    state.canUsePoint = payload;
  },
  SET_LAST_TIME_ACTIVE (state, payload) {
    state.lastTimeActive = payload;
  },
};
const actions = {
  async login ({ commit }, payload) {
    const resLogin = await http.post(apiEndpoints.login, payload);
    const { status, data } = resLogin;

    if (status) {
      const { token, user } = data.result;

      commit('SET_AUTH', {
        token,
        user,
      });

      return {
        status: true,
      };
    } else {
      return {
        status: false,
        errors: data.errors,
        code: data.code,
        message: data.message,
      };
    }
  },
  async getAuthUser ({ commit, rootState }, payload) {
    if (!rootState.isOnline) {
      return {
        status: false,
      };
    }
    const resLogin = await http.get(apiEndpoints.me);
    const { status, data } = resLogin;
    // console.log(status, data);
    if (status) {
      const user = data.result;

      commit('SET_AUTH_USER', {
        user,
      });

      return {
        status: true,
      };
    } else {
      return {
        status: false,
        errors: data.errors,
        code: data.code,
        message: data.message,
      };
    }
  },
  async logout ({ commit }, payload) {
    await http.post(apiEndpoints.logout);
    commit('UNSET_USER');
    return true;
  },
  async updateActivity ({ dispatch, rootState, state }) {
    const secondsDelay = Math.round(parseInt(process.env.VUE_APP_ACTIVITY_DELAY || 30) / 1000);

    if (!(state && state.token)) {
      return false;
    }
    if (!rootState.isOnline) {
      return false;
    }

    if (state.lastTimeActive) {
      const momentLastActive = moment.tz(state.lastTimeActive, 'YYYY-MM-DD HH:mm:ss', 'Asia/Tokyo');
      const diffSeconds = moment().tz('Asia/Tokyo').diff(momentLastActive, 'seconds');
      if (diffSeconds < secondsDelay) {
        return false;
      }
    }

    dispatch('setLastTimeActive', moment().tz('Asia/Tokyo').format('YYYY-MM-DD HH:mm:ss'));

    await http.postWithoutLoading(apiEndpoints.update_activity);
  },
  setLastTimeActive ({ commit }, payload) {
    commit('SET_LAST_TIME_ACTIVE', payload);
  },
};
export default {
  state,
  mutations,
  actions,
  getters,
};
