import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import http from '@/services/http';
import Constant from '@/configs/constant.js';
import './plugins';
import vuetify from '@/plugins/vuetify';
import i18n from '@/locales/i18n';
import * as filters from './filters';
import * as helpers from '@/helpers';
import moment from 'moment-timezone';
import '@fontsource/noto-sans-jp';
import _ from 'lodash';

moment.tz.setDefault('Asia/Tokyo');
Vue.config.productionTip = false;
Vue.prototype.$constant = Constant;
Vue.prototype.$helpers = helpers;
Vue.prototype.$moment = moment;
Vue.prototype.$fileUrl = process.env.VUE_APP_FILE_URL;
Vue.prototype.$http = http;
Vue.prototype.$_ = _;

Object.keys(filters).forEach(key => { Vue.filter(key, filters[key]); });
new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
