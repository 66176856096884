import axios from 'axios';
import store from '@/store';
import moment from 'moment-timezone';

const API_URL = process.env.VUE_APP_API_URL + '/api/';

axios.defaults.baseURL = API_URL;
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.interceptors.request.use(async function (config) {
  const token = store.getters['userStore/token'];
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, function (err) {
  return Promise.reject(err);
});

axios.interceptors.response.use(
  function (response) {
    if (response.headers && response.headers['last-active-at']) {
      store.dispatch('userStore/setLastTimeActive', moment(response.headers['last-active-at']).format('YYYY-MM-DD HH:mm:ss'));
    }
    return response;
  }, function (error) {
    return Promise.reject(error);
  });

export default {
  request (method, url, params, data, headers = {}, config = {}) {
    return axios.request({ ...config, url, params, data, method: method.toLowerCase(), headers });
  },

  get (url, params) {
    return this.request('get', url, params, {});
  },

  post (url, data, headers = {}, config = {}) {
    return this.request('post', url, {}, data, headers, config);
  },

  put (url, data) {
    return this.request('put', url, {}, data);
  },

  patch (url, data) {
    return this.request('patch', url, {}, data);
  },

  delete (url, data = {}) {
    return this.request('delete', url, {}, data);
  },

  async download (url, params = {}) {
    return this.request('get', url, params, {}, {}, { responseType: 'blob' });
  },

  init () {
    axios.defaults.baseURL = process.env.VUE_APP_API_URL;
    axios.defaults.headers.common.Accept = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
  },

  setSessionIdHeader (sessionId) {
    axios.defaults.headers.common.Authorization = 'Bearer ' + sessionId;
  },

  removeSessionIdHeader () {
    axios.defaults.headers.common.Authorization = '';
  },
  setTimeout (time) {
    axios.defaults.timeout = time || 30;
  },
};
