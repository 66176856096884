export default {
  label: {
    search: '検索',
    require: '必須',
    create: '新規作成',
    logout: 'ログアウト',
    yen: '円',
    action: 'アクション',
    confirm_title: '確認',
    from: 'から',
    to: 'まで',
    point: 'ポイント',
    sheet: '枚',
    time: '倍',
  },
  button: {
    search: '検索',
    import_csv: 'CSV投入',
    export_csv: 'CSV出力',
    create: '新規作成',
    register: '登録',
    edit: '訂正',
    keep: '保存',
    detail: '詳細',
    delete: '削除',
    complete: '完了',
    cancel: 'キャンセル',
    yes: 'はい',
    no: 'いいえ',
    back: '戻る',
    next: '次へ',
    close: '閉じる',
    download: 'ファイル出力',
    upload: '反映',
    confirm: '確認',
    logout: 'ログアウト',
    back_to_home: 'ホームへ戻る',
    retry: 'リトライ',
    login: 'ログイン',
    ok: 'OK',
    reset: 'リセット',
  },
  message: {
    no_data: 'データなし。',
    loading: 'ローディング。',
    invalid: '{itemName}が有効ではありません。',
    force_login: '他のユーザーがログイン中',
    error: 'エラーが発生しました。',
    no_permission: 'この操作を実行するのに十分な権限がありません。',
    no_connection: 'ネットワーク接続が切れました。<br>復旧したらこの通知がなくなります。<br>ご更新をしないでください。',
    connection_recovered: 'ネットワークに接続しています。',
  },
  sidebar: {
    credit_card: '取引開始',
    point_ticket: 'ポイント券利用',
    transaction_history: '取引履歴検索',
  },
  link: {
    home: 'ホーム',
    start_transaction: '取引開始',
    use_point_ticket: 'ポイント券利用',
    search_transaction_history: '取引履歴検索',
  },
  maintain: {
    title: 'メンテナンス中です。',
    time_notice: '【メンテナンス日時】',
    message: 'ご利用の皆様にはご迷惑をおかけし、申し訳ございません。',
  },
  timeout: {
    before: {
      title: '間もなく接続を終了します',
      msg1: 'セキュリティ保護のため、間もなく接続を終了します。',
      msg2: '継続して利用される方はOKを押してください。',
    },
    outed: {
      msg1: 'セッションの有効期限が切れています。',
      msg2: '再度ログインを行なってください。',
    },
  },
};
