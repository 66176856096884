import http from '@/services/http';
import apiEndpoints from '@/services/apiEndpoints';

const getDefaultState = () => {
  return {
    newTransactionAddConfirmData: {},
    newTransactionUseConfirmData: {},
  };
};

const state = getDefaultState();
const getters = {
  newTransactionAddConfirmData: state => state.newTransactionAddConfirmData,
  newTransactionUseConfirmData: state => state.newTransactionUseConfirmData,
};
const mutations = {
  SET_TRANSACTION_ADD_CONFIRM_DATA (state, payload) {
    state.newTransactionAddConfirmData = payload;
  },
  SET_TRANSACTION_USE_CONFIRM_DATA (state, payload) {
    state.newTransactionUseConfirmData = payload;
  },
};
const actions = {
  async checkStoreLimitPoint (_, payload) {
    return await http.post(apiEndpoints.check_store_point_limit, payload);
  },
  async create (_, payload) {
    return await http.post(apiEndpoints.create_transaction, payload);
  },
  async updateAddPoint (_, payload) {
    return await http.post(apiEndpoints.update_add_point_transaction.replace(':id', payload.id), payload);
  },
  async updateUsePoint (_, payload) {
    return await http.post(apiEndpoints.update_use_point_transaction.replace(':id', payload.id), payload);
  },
  resetConfirmData ({ commit }) {
    commit('SET_TRANSACTION_ADD_CONFIRM_DATA', {});
    commit('SET_TRANSACTION_USE_CONFIRM_DATA', {});
  },
};
export default {
  state,
  mutations,
  actions,
  getters,
};
