import http from '@/services/http';
import apiEndpoints from '@/services/apiEndpoints';
import constant from '@/configs/constant';

const getDefaultState = () => {
  return {};
};

const state = getDefaultState();
const getters = {};
const mutations = {};
const actions = {
  async getBalance (_, cardNumber) {
    return await http.getWithLoading(apiEndpoints.card_balance.replace('{card_number}', cardNumber), {}, constant.API_GET_BALANCE_TIME_OUT);
  },
  async getBalanceWithoutLoading (_, cardNumber) {
    return await http.get(apiEndpoints.card_balance.replace('{card_number}', cardNumber));
  },
};
export default {
  state,
  mutations,
  actions,
  getters,
};
