import constant from '@/configs/constant';

export default class CardService {
  setCardSpecs (cardSpecs) {
    // console.log('cardSpecs', cardSpecs);
    this.cardSpecs = cardSpecs;
    this.card_number_trimmed = '';
  }

  getCardNumberTrimmed () {
    return this.card_number_trimmed;
  }

  validateBarcode (barCodeResult) {
    // if (barCodeResult) {
    //   return true;
    // }
    if (this.cardSpecs.length === 0) {
      return false;
    }

    for (let index = 0; index < this.cardSpecs.length; index++) {
      const cardSpec = this.cardSpecs[index];
      if (this.validateBarcodeDetail(cardSpec, barCodeResult)) {
        return true;
      }
    }

    return false;
  }

  /*
    return false là không xác thực
    return true là xác thực
  */
  validateBarcodeDetail (cardSpec, barCodeResult) {
    barCodeResult.card_number = barCodeResult.code;
    this.card_number_trimmed = barCodeResult.code;
    if (cardSpec.number_of_digits_card_number === cardSpec.number_of_digits_id) {
      if (barCodeResult.code.length !== cardSpec.number_of_digits_card_number) {
        return false;
      }
    } else {
      if (barCodeResult.code.length !== cardSpec.number_of_digits_card_number) {
        if (barCodeResult.code.length !== cardSpec.number_of_digits_id) {
          return false;
        } else {
          barCodeResult.card_number = barCodeResult.code.substr(cardSpec.card_number_start_position - 1, cardSpec.number_of_digits_card_number);
          this.card_number_trimmed = barCodeResult.code.substr(cardSpec.card_number_start_position - 1, cardSpec.number_of_digits_card_number);
        }
      }
    }

    if (barCodeResult.format) {
      const format = constant.code_type[barCodeResult.format];
      if (cardSpec.code_type.indexOf(format) === -1) {
        return false;
      }
    }

    // check first 4 digit
    const firstFourDigit = Number(barCodeResult.card_number.substr(0, 4));
    if (!Array.isArray(cardSpec.id_first_four_digits)) {
      return false;
    }

    if (cardSpec.id_first_four_digits.length > 0) {
      if (cardSpec.card_type === constant.card_type.INDIVIDUAL) {
        if (cardSpec.id_first_four_digits.map((x) => Number(x)).indexOf(firstFourDigit) === -1) {
          // console.log('INDIVIDUAL NOT MATCH');
          return false;
        }
      } else if (cardSpec.card_type === constant.card_type.RANGE && cardSpec.id_first_four_digits.length === 2) {
        const settingRangeFrom = (cardSpec.id_first_four_digits && cardSpec.id_first_four_digits[0] === null) ? 0 : Number(cardSpec.id_first_four_digits[0]);
        const settingRangeTo = (cardSpec.id_first_four_digits && cardSpec.id_first_four_digits[1] === null) ? 9999 : Number(cardSpec.id_first_four_digits[1]);

        if (firstFourDigit < settingRangeFrom || firstFourDigit > settingRangeTo) {
          return false;
        }
      }
    }

    // check cd formula
    if (cardSpec.cd_formula) {
      switch (parseInt(cardSpec.cd_formula)) {
        case constant.cd_formula.MODULE_10:
          {
            const check = this.checkModule10(barCodeResult.card_number, cardSpec.cd_start_position, cardSpec.number_of_digits_cd);
            if (!check) {
              return false;
            }
          }
          break;
        case constant.cd_formula.MODULE_11:
          {
            const check = this.checkModule11(barCodeResult.card_number, cardSpec.cd_start_position, cardSpec.number_of_digits_cd);
            if (!check) {
              return false;
            }
          }
          break;
        case constant.cd_formula.MODULE_10_WEIGHT_3:
          {
            const check = this.checkModule10Weight3(barCodeResult.card_number, cardSpec.cd_start_position, cardSpec.number_of_digits_cd);
            if (!check) {
              return false;
            }
          }
          break;
        case constant.cd_formula.MODULE_10_WEIGHT_2:
          {
            const check = this.checkModule10Weight2(barCodeResult.card_number, cardSpec.cd_start_position, cardSpec.number_of_digits_cd);
            if (!check) {
              return false;
            }
          }
          break;
        default:
          break;
      }
    }

    // check company code
    if (cardSpec.company_code !== null) {
      if (Number(barCodeResult.code.substr(cardSpec.company_code_start_position - 1, cardSpec.number_of_digits_company_code)) !== cardSpec.company_code) {
        return false;
      }
    }
    return true;
  }

  validateQRcode (qrCodeResult) {
    // if (qrCodeResult) {
    //   return true;
    // }
    const cardSpecs = this.cardSpecs.filter(item => item.code_type && item.code_type.includes(constant.code_type.qr_code));
    if (cardSpecs.length === 0) {
      return false;
    }

    for (let index = 0; index < cardSpecs.length; index++) {
      const cardSpec = cardSpecs[index];
      if (this.validateQRcodeDetail(cardSpec, qrCodeResult)) {
        return true;
      }
    }

    return false;
  }

  validateQRcodeDetail (cardSpec, qrCodeResult) {
    this.card_number_trimmed = qrCodeResult;
    if (cardSpec.number_of_digits_card_number === cardSpec.number_of_digits_id) {
      if (qrCodeResult.length !== cardSpec.number_of_digits_card_number) {
        return false;
      }
    } else {
      if (qrCodeResult.length !== cardSpec.number_of_digits_card_number) {
        if (qrCodeResult.length !== cardSpec.number_of_digits_id) {
          return false;
        } else {
          qrCodeResult = qrCodeResult.substr(cardSpec.card_number_start_position - 1, cardSpec.number_of_digits_card_number);
          this.card_number_trimmed = qrCodeResult;
        }
      }
    }

    // check first 4 digit
    const firstFourDigit = Number(qrCodeResult.substr(0, 4));
    if (!Array.isArray(cardSpec.id_first_four_digits)) {
      return false;
    }

    if (cardSpec.id_first_four_digits.length > 0) {
      if (cardSpec.card_type === constant.card_type.INDIVIDUAL) {
        if (cardSpec.id_first_four_digits.map((x) => Number(x)).indexOf(firstFourDigit) === -1) {
          // console.log('INDIVIDUAL NOT MATCH');
          return false;
        }
      } else if (cardSpec.card_type === constant.card_type.RANGE && cardSpec.id_first_four_digits.length === 2) {
        const settingRangeFrom = (cardSpec.id_first_four_digits && cardSpec.id_first_four_digits[0] === null) ? 0 : Number(cardSpec.id_first_four_digits[0]);
        const settingRangeTo = (cardSpec.id_first_four_digits && cardSpec.id_first_four_digits[1] === null) ? 9999 : Number(cardSpec.id_first_four_digits[1]);

        if (firstFourDigit < settingRangeFrom || firstFourDigit > settingRangeTo) {
          return false;
        }
      }
    }

    // check cd formula
    if (cardSpec.cd_formula) {
      switch (parseInt(cardSpec.cd_formula)) {
        case constant.cd_formula.MODULE_10:
          {
            const check = this.checkModule10(qrCodeResult, cardSpec.cd_start_position, cardSpec.number_of_digits_cd);
            if (!check) {
              return false;
            }
          }
          break;
        case constant.cd_formula.MODULE_11:
          {
            const check = this.checkModule11(qrCodeResult, cardSpec.cd_start_position, cardSpec.number_of_digits_cd);
            if (!check) {
              return false;
            }
          }
          break;
        case constant.cd_formula.MODULE_10_WEIGHT_3:
          {
            const check = this.checkModule10Weight3(qrCodeResult, cardSpec.cd_start_position, cardSpec.number_of_digits_cd);
            if (!check) {
              return false;
            }
          }
          break;
        case constant.cd_formula.MODULE_10_WEIGHT_2:
          {
            const check = this.checkModule10Weight2(qrCodeResult, cardSpec.cd_start_position, cardSpec.number_of_digits_cd);
            if (!check) {
              return false;
            }
          }
          break;
        default:
          break;
      }
    }

    // check company code
    if (cardSpec.company_code !== null) {
      if (Number(qrCodeResult.substr(cardSpec.company_code_start_position - 1, cardSpec.number_of_digits_company_code)) !== cardSpec.company_code) {
        return false;
      }
    }
    return true;
  }

  checkModule10Weight3 (cardNumber, cdStartPosition, numberOfDigitsCd) {
    const mainPart = cardNumber.substr(0, cdStartPosition - 1);
    const cdPart = Number(cardNumber.substr(cdStartPosition - 1, numberOfDigitsCd));
    const mainParts = mainPart.split('').reverse();
    let total = 0;
    for (let index = 0; index < mainParts.length; index++) {
      const part = mainParts[index];
      const multi = index % 2 === 0 ? 3 : 1;
      total += part * multi;
    }
    return cdPart === (Math.ceil(total / 10) * 10 - (total % 10) - (Math.floor(total / 10) * 10));
  }

  checkModule10Weight2 (cardNumber, cdStartPosition, numberOfDigitsCd) {
    const mainPart = cardNumber.substr(0, cdStartPosition - 1);
    const cdPart = Number(cardNumber.substr(cdStartPosition - 1, numberOfDigitsCd));
    const mainParts = mainPart.split('').reverse();

    let total = 0;
    for (let index = 0; index < mainParts.length; index++) {
      const multi = index % 2 === 0 ? 2 : 1;
      const part = mainParts[index];
      const subTotal = part * multi;
      const realSub = subTotal < 10 ? subTotal : String(subTotal).split('').reduce((accumulator, currentValue) => Number(accumulator) + Number(currentValue), 0);
      total += realSub;
    }
    return cdPart === (Math.ceil(total / 10) * 10 - (total % 10) - (Math.floor(total / 10) * 10));
  }

  checkModule10 (cardNumber, cdStartPosition, numberOfDigitsCd) {
    const mainPart = cardNumber.substr(0, cdStartPosition - 1);
    const cdPart = Number(cardNumber.substr(cdStartPosition - 1, numberOfDigitsCd));
    const mainParts = mainPart.split('').reverse();
    let total = 0;
    for (let index = 0; index < mainParts.length; index++) {
      const part = mainParts[index];
      const indexOfThree = index % 3;
      const multi = indexOfThree === 0 ? 7 : indexOfThree === 1 ? 3 : 1;
      total += part * multi;
    }
    return cdPart === (Math.ceil(total / 10) * 10 - (total % 10) - (Math.floor(total / 10) * 10));
  }

  checkModule11 (cardNumber, cdStartPosition, numberOfDigitsCd) {
    const mainPart = cardNumber.substr(0, cdStartPosition - 1);
    const cdPart = Number(cardNumber.substr(cdStartPosition - 1, numberOfDigitsCd));
    const mainParts = mainPart.split('').reverse();
    let total = 0;
    for (let index = 0; index < mainParts.length; index++) {
      const part = mainParts[index];
      const multi = index + 2;
      total += part * multi;
    }
    const mod = total % 11;
    const calcSub = String(11 - mod);
    const calCd = mod === 0 ? 0 : Number(calcSub.substring(calcSub.length - 1));
    return cdPart === calCd;
  }

  handleBarcode (barCodeResult) {
    if (this.cardSpecs.length === 0) {
      return barCodeResult.code;
    }
    for (let index = 0; index < this.cardSpecs.length; index++) {
      const cardSpec = this.cardSpecs[index];

      if (cardSpec.number_of_digits_card_number === cardSpec.number_of_digits_id &&
          barCodeResult.code.length === cardSpec.number_of_digits_id) {
        return barCodeResult.code;
      } else {
        if (barCodeResult.code.length !== cardSpec.number_of_digits_card_number) {
          if (barCodeResult.code.length !== cardSpec.number_of_digits_id) {
            continue;
          } else {
            return barCodeResult.code.substr(cardSpec.card_number_start_position - 1, cardSpec.number_of_digits_card_number);
          }
        }
      }
    }
    return barCodeResult.code;
  }
};
