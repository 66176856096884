export default {
  button: {
    special_rate: '特別掛率',
    special_addition: '特別加算',
    use_points: 'ポイント利用',
    add_points: 'ポイント加算',
  },
  label: {
    title: 'ポイント加算',
    card_no: 'カード番号',
    point_ticket: 'ポイント券',
    purchase_amount: '購入金額',
    basic_points: '基本ポイント',
    bonus_points: 'ボーナスポイント',
    benefit_points: '掛率ポイント',
    multiply_points: '掛率ポイント',
    additional_points: '加算ポイント',
    total_bonus_points: 'ボーナスポイント合計',
    total_added_points: 'ポイント加算合計',
    benefit: '掛率',
    multiply: '掛率',
    addition: '加算',
    point_balance: '現在ポイント',
    point_unit: 'ポイント利用（{unit}ポイント単位）',
    tax_percent: '税{percent}%分',
    total_point_payment: 'ポイント利用合計',
    use_points: 'ポイント利用',
    input_content_confirm: '入力内容確認',
    register_complete: '登録完了',
  },
  breadcrumb: {
    add_points: 'ポイント加算',
    use_points: 'ポイント利用',
    use_point_ticket: 'ポイント券利用',
    transaction_confirm: '取引確認',
    transaction_complete: '取引完了',
  },
  message: {
    warning: '利用ポイントに誤りがあります。ご確認ください。',
    transaction_complete: '取引が完了しました。<br>ポイントの加算は翌日以降となります。',
  },
};
