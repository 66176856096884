import Vue from 'vue';
import InitLayout from '@/layouts/Init.vue';
import DefaultLayout from '@/layouts/Default.vue';
import BlankLayout from '@/layouts/Blank.vue';
import SvgIcon from '@/components/common/SvgIcon.vue';
import Dialog from '@/components/common/Dialog.vue';
import DialogBarcodeScan from '@/components/common/DialogBarcodeScan.vue';
import DatePicker from '@/components/common/DatePicker.vue';
import Snackbar from '@/components/common/Snackbar.vue';
import Pagination from '@/components/common/Pagination.vue';
import Breadcrumb from '@/components/common/Breadcrumb.vue';
import SectionBox from '@/components/common/SectionBox.vue';
import ProgressCircular from '@/components/common/ProgressCircular.vue';
import Loading from '@/components/common/Loading.vue';
import DialogQRcodeScan from '@/components/common/DialogQRcodeScan.vue';

Vue.component('init-layout', InitLayout);
Vue.component('default-layout', DefaultLayout);
Vue.component('blank-layout', BlankLayout);
Vue.component('svg-icon', SvgIcon);
Vue.component('user-dialog', Dialog);
Vue.component('dialog-barcode-scan', DialogBarcodeScan);
Vue.component('dialog-qr-code-scan', DialogQRcodeScan);
Vue.component('date-picker', DatePicker);
Vue.component('snack-bar', Snackbar);
Vue.component('user-pagination', Pagination);
Vue.component('top-breadcrumb', Breadcrumb);
Vue.component('section-box', SectionBox);
Vue.component('progress-circular', ProgressCircular);
Vue.component('loading-screen', Loading);
