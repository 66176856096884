import moment from 'moment';

export function date (date, format = 'YYYY/MM/DD') {
  if (date) {
    return moment(date).format(format);
  }
  return '';
}

export function datetime (datetime, formatDatetime = 'YYYY/MM/DD HH:mm') {
  if (datetime) {
    return moment(datetime).format(formatDatetime);
  }
  return '';
}
