export default {
  button: {
    verification: '確認',
    retry: 'リトライ',
  },
  label: {
    title: 'カード番号入力',
    card_no: 'カード番号',
  },
  errors: {
    reload_and_scan: 'リロードしてスキャン',
    camera_blocked: 'ブラウザでカメラへのアクセスがブロックされています。カメラへのアクセスを許可してください',
    input_card_number_manualize: 'カード番号を手入力してください',
  },
};
