<template>
  <div class="progress-wrapper">
    <div class="lds-spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ProgressCircular',
};
</script>
<style>
.progress-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom:  0;
  left: 0;
  z-index: 9999;
  background: rgba(255, 255, 255, .6);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
