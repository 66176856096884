<template>
  <v-dialog
    v-model="dialog"
    :class="dialogClass"
    :content-class="classList.join(' ')"
    width="auto"
    persistent
  >
    <v-card>
      <v-card-title class="dialog-header">
        <h4 class="mx-auto">
          <slot name="header"></slot>
        </h4>
      </v-card-title>
      <v-card-text class="dialog-body">
        <slot></slot>
      </v-card-text>
      <v-card-actions class="dialog-footer">
        <slot name="footer"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
const DIALOG_TYPE_MESSAGE = 0;
const DIALOG_TYPE_FORM = 1;

export default {
  name: 'UserDialog',
  props: {
    classList: { type: [String, Array, Object], require: false, default: () => [] },
    dialogType: { type: Number, default: DIALOG_TYPE_MESSAGE },
  },
  data () {
    return {
      dialog: false,
    };
  },
  methods: {
    open () {
      this.dialog = true;
    },
    close () {
      this.$emit('close');
      this.dialog = false;
    },
  },
  computed: {
    dialogClass () {
      return {
        'minfill-width0': this.dialogType === DIALOG_TYPE_FORM,
      };
    },
  },
};
</script>
