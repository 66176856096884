<template>
  <main class="blank-container" :class="{ 'has-background' : $route.meta.is_has_bg }">
    <slot />
  </main>
</template>

<script>
export default {
  name: 'BlankLayout',
  components: {},
};
</script>
<style scoped>
</style>
