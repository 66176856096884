export default {
  messages: {
    alpha: 'The {_field_} field may only contain alphabetic characters',
    alpha_num: 'The {_field_} field may only contain alpha-numeric characters',
    alpha_dash: 'The {_field_} field may contain alpha-numeric characters as well as dashes and underscores',
    alpha_spaces: 'The {_field_} field may only contain alphabetic characters as well as spaces',
    between: 'The {_field_} field must be between {min} and {max}',
    confirmed: 'パスワードとパスワード（確認）は同じ値を入力してください。',
    digits: 'The {_field_} field must be numeric and exactly contain {length} digits',
    dimensions: 'The {_field_} field must be {width} pixels by {height} pixels',
    email: '正しいメールアドレスの形式を入力してください。',
    excluded: 'The {_field_} field is not a valid value',
    ext: 'The {_field_} field is not a valid file',
    image: 'The {_field_} field must be an image',
    integer: '半角数字のみ入力できます。',
    length: 'The {_field_} field must be {length} long',
    max_value: '{max}以下の数字を入力してください。',
    max: '{_field_}が最大文字数の{length}文字を超過しています。',
    mimes: 'The {_field_} field must have a valid file type',
    min_value: '{min}以上の数字を入力してください。',
    min: 'The {_field_} field must be at least {length} characters',
    numeric: '半角数字のみ入力できます。',
    oneOf: 'The {_field_} field is not a valid value',
    regex: 'The {_field_} field format is invalid',
    required_if: '{_field_}を入力してください。',
    required: '{_field_}を入力してください。',
    size: 'The {_field_} field size must be less than {size}KB',
    double: 'The {_field_} field must be a valid decimal',
    is: 'is error',
    is_not: 'is_not error',
    error_phone_number: '正しい電話番号の形式を入力してください。',
    range_number: '{min}以上{max}以下の数字を入力してください。',
    invalid: '{_field_}が有効ではありません。',
  },
};
