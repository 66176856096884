import http from '@/services/http';
import apiEndpoints from '@/services/apiEndpoints';

const getDefaultState = () => {
  return {};
};

const state = getDefaultState();
const getters = {};
const mutations = {};
const actions = {
  async useTicket (_, payload) {
    return await http.post(apiEndpoints.use_point_ticket, payload);
  },
};
export default {
  state,
  mutations,
  actions,
  getters,
};
