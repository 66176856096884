import http from '@/services/http';
import apiEndpoints from '@/services/apiEndpoints';

const getDefaultState = () => {
  return {
    taxes: [],
  };
};

const state = getDefaultState();
const getters = {
  taxes: state => state.taxes,
};
const mutations = {
  SET_TAXES (state, payload) {
    state.taxes = payload;
  },
};
const actions = {
  async all ({ commit }) {
    const response = await http.get(apiEndpoints.taxes);
    const { status, data } = response;

    if (status) {
      commit('SET_TAXES', data.result);
    }
  },
};
export default {
  state,
  mutations,
  actions,
  getters,
};
