import i18n from '@/locales/i18n';
import companyConstant from './companyConstant';
import transactionConstant from './transactionConstant';
import promotionConstant from './promotionConstant';

const constant = {
  max_file_size: 5242880, // 5MB
  image_types: ['jpg', 'jpeg', 'png'],
  pagination_total_visible: 7,
  perpage_max: 1000,
  pagination_options: [
    {
      text: 20,
      value: 20,
    },
    {
      text: 50,
      value: 50,
    },
    {
      text: 100,
      value: 100,
    },
    {
      text: i18n.t('common.options.all'),
      value: 1000,
    },
  ],
  checkbox_values: {
    true: 1,
    false: 0,
  },
  step_input: 1,
  step_confirm: 2,
  step_complete: 3,
  months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  api_error_code: {
    error_conflict_login: '600',
  },
  breakpoint_mobile: 960,
  per_page_options: [20, 50, 100],
  per_page_default: 20,
  company: companyConstant,
  cd_formula: {
    MODULE_10: 1,
    MODULE_11: 2,
    MODULE_10_WEIGHT_3: 3,
    MODULE_10_WEIGHT_2: 4,
  },
  card_type: {
    INDIVIDUAL: 'INDIVIDUAL',
    RANGE: 'RANGE',
  },
  code_type: {
    codabar: 'NW7',
    code_128: 'CODE128',
    ean_13: 'JAN',
    jisii: 'JISII',
    qr_code: 'QRCODE',
  },
  system: {
    API_AVAILABLE: 1,
    API_NOT_AVAILABLE: 0,
  },
  card_spec: {
    FINAL_STATUS_INVALID: 0,
    FINAL_STATUS_VALID: 1,
  },
  api_available: 1,
  transaction: transactionConstant,
  SORT_DESC: 'DESC',
  SORT_ASC: 'ASC',
  promotion: promotionConstant,
  moment_format: {
    date: 'YYYY年MM月DD日',
    time: 'HH:mm',
    date_time: 'YYYY年MM月DD日 HH:mm',
  },
  API_GET_BALANCE_TIME_OUT: 30,
};

export default constant;
