<template>
  <div class="wrapper" id="main-app">

    <div class="main">
      <user-header></user-header>
      <main class="content" :class="contentClasses">
        <keep-alive v-if="$route.meta.keepAlive">
          <router-view :key="$route.path"></router-view>
        </keep-alive>
        <router-view v-else :key="$route.fullPath"></router-view>
      </main>
    </div>
  </div>
</template>

<script>
import UserHeader from '@/layouts/user/Header.vue';

export default {
  name: 'DefaultLayout',
  components: {
    UserHeader,
  },
  data () {
    return {
    };
  },
  created () {
    //
  },
  computed: {
    contentClasses () {
      const wideContainerScreens = {
        transaction_history: true,
        transaction_list: true,
      };
      return wideContainerScreens[this.$route.name] ? 'content--wide' : '';
    },
  },
};
</script>
