export default {
  button: {
    add_points: 'ポイント加算',
    transaction_correction: '取引訂正',
    use_points: 'ポイント利用',
    cancel: '取消',
    edit: '修正',
    special_multiply: '特別掛率',
    special_addition: '特別加算',
  },
  label: {
    title: '取引メニュー',
    card_no: 'カード番号',
    basic_point: '基本ポイント',
    current_points: '現在ポイント数',
    from: 'から',
    to: 'まで',
    transaction_id: '取引ID',
    child_transaction_id: '派生取引ID',
    source_transaction_id: '親取引ID',
    purchase_amount: '購入金額',
    bonus_points: 'ボーナスポイント',
    total_bonus_points: 'ボーナスポイント合計',
    total_use_points_payment: 'ポイント利用合計',
    multiply_points: '掛率ポイント',
    bonus_multiply_point: '掛率ポイント',
    additional_points: '加算ポイント',
    bonus_add_point: '加算ポイント',
    total_added_points: 'ポイント加算合計',
    transaction_datetime: '取引日時',
    sync_datetime: '同期日時',
    type: '区分',
    user: 'ユーザー',
    status: 'ステータス',
    user_point_before_transaction: '処理前ポイント残高',
    tax: 'ポイント利用',
    use_point: 'ポイント利用',
    total_use_points: 'ポイント利用合計',
    add_point_statics: 'ポイント加算',
    add_point_detail: 'ポイント加算明細',
    use_point_detail: 'ポイント利用明細',
    promotion_code: 'プロモーションコード',
  },
  table: {
    card_no: 'カード番号',
    transaction_id: '取引ID',
    transaction_datetime: '取引日時',
    point_quantity: 'P数',
    purchase_amount: '購入金額',
    type: '区分',
    status: 'ステータス',
  },
  breadcrumb: {
    transaction_history: '取引履歴',
    transaction_list: '取引履歴検索',
    transaction_detail: '取引詳細',
    transaction_edit: '取引訂正',
    transaction_confirm: '取引確認',
    transaction_complete: '取引完了',
  },
  message: {
    edit_note: '修正する値を入力してください',
    confirm_modify: 'この内容で修正してもよろしいですか？',
    transaction_complete: '取引が完了しました。<br>ポイントの加算は翌日以降となります。',
    transaction_add_complete: '取引が完了しました。<br>ポイントの加算は翌日以降となります。',
    transaction_use_complete: '取引が完了しました。',
    transaction_update: '訂正が完了しました。',
    confirm_cancel: 'この取引を取消してもよろしいですか?',
    total_use_point_invalid: 'ポイント利用合計が有効ではありません。',
    total_add_point_invalid: '入力内容に誤りがあります。',
    total_point_invalid: '入力内容に誤りがあります。',
    confirm_reset: 'このボーナスポイントをリセットしてもよろしいですか?',
  },
  dialog: {
    add_point: {
      title: '加算ポイント',
    },
    multiply_point: {
      title: '掛率ポイント',
    },
    use_point: {
      title: '',
    },
    cancel_confirm: {
      message: 'この取引を取消してもよろしいですか。',
    },
    cancel_complete: {
      message: '取消が完了しました。',
    },
    barcode_input: {
      title: 'プロモーションコード',
      message_invalid_promotion: 'プロモーションコードが有効ではありません。',
    },
  },
};
