import Vue from 'vue';
import Vuex from 'vuex';
import modules from './modules/modules';
import createPersistedState from 'vuex-persistedstate';
import gettersModule from './getters_module';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isShowLoading: false,
    isMobileSize: true,
    isOnline: true,
    meta: {},
  },
  getters: {
    isShowLoading: state => state.isShowLoading,
    isMobileSize: (state) => state.isMobileSize,
    isOnline: (state) => state.isOnline,
    meta: (state) => state.meta,
    ...gettersModule,
  },
  mutations: {
    SET_IS_SHOW_LOADING: (state, payload) => {
      state.isShowLoading = payload;
    },
    SET_IS_MOBILE_SIZE (state, payload) {
      state.isMobileSize = payload;
    },
    SET_IS_ONLINE (state, payload) {
      state.isOnline = payload;
    },
    SET_META (state, payload) {
      state.meta = payload;
    },
  },
  actions: {
    setIsShowLoading: ({ commit }, payload) => {
      commit('SET_IS_SHOW_LOADING', payload);
    },
    setIsMobileSize ({ commit }, payload = null) {
      commit('SET_IS_MOBILE_SIZE', payload);
    },
    setIsOnline ({ commit }, payload = null) {
      commit('SET_IS_ONLINE', payload);
    },
    setMeta ({ commit }, payload = null) {
      commit('SET_META', payload);
    },
  },
  modules,
  plugins: [createPersistedState({
    paths: ['userStore'],
    key: 'ps_data',
  })],
});
