import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err);
};

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: { layout: 'blank', is_has_bg: true },
  },
  {
    path: '/',
    name: 'home',
    component: () =>
      import(/* webpackChunkName: "home" */ '../views/HomePage.vue'),
    meta: { requiresAuth: true, layout: 'default' },
  },
  {
    path: '/card-input',
    name: 'card_input',
    component: () =>
      import(/* webpackChunkName: "card-reader" */ '../views/card/CardInput.vue'),
    meta: { requiresAuth: true, layout: 'default' },
  },
  {
    path: '/transactions/menu',
    name: 'transaction_menu',
    component: () =>
      import(/* webpackChunkName: "transaction" */ '../views/transaction/TransactionMenu.vue'),
    meta: { requiresAuth: true, layout: 'default' },
  },
  {
    path: '/transactions/add-points/:transaction_id/edit',
    name: 'update_add_points',
    meta: { requiresAuth: true, layout: 'default', keepAlive: true },
    component: () =>
      import(/* webpackChunkName: "transactions" */ '../views/transaction/TransactionAddPoint.vue'),
  },
  {
    path: '/transactions/use-points/:transaction_id/edit',
    name: 'update_use_points',
    meta: { requiresAuth: true, layout: 'default', keepAlive: true },
    component: () =>
      import(/* webpackChunkName: "transactions" */ '../views/transaction/TransactionUsePoint.vue'),
  },
  {
    path: '/transactions/add-points',
    name: 'add_points',
    meta: { requiresAuth: true, layout: 'default', keepAlive: true },
    component: () =>
      import(/* webpackChunkName: "transactions" */ '../views/transaction/TransactionAddPoint.vue'),
  },
  {
    path: '/transactions/use-points',
    name: 'use_points',
    meta: { requiresAuth: true, layout: 'default', keepAlive: true },
    component: () =>
      import(/* webpackChunkName: "transactions" */ '../views/transaction/TransactionUsePoint.vue'),
  },
  {
    path: '/transactions/:transaction_id/update/confirm',
    name: 'transaction_add_point_update_confirm',
    meta: { requiresAuth: true, layout: 'default', keepAlive: true },
    component: () =>
      import(/* webpackChunkName: "transaction" */ '../views/transaction/TransactionCreateConfirm.vue'),
  },
  {
    path: '/transactions-use-point/:transaction_id/update/confirm',
    name: 'transaction_use_point_update_confirm',
    meta: { requiresAuth: true, layout: 'default', keepAlive: true },
    component: () =>
      import(/* webpackChunkName: "transaction" */ '../views/transaction/TransactionCreateConfirm.vue'),
  },
  {
    path: '/transactions/create/confirm/',
    name: 'transaction_create_confirm',
    meta: { requiresAuth: true, layout: 'default', keepAlive: true },
    component: () =>
      import(/* webpackChunkName: "transaction" */ '../views/transaction/TransactionCreateConfirm.vue'),
  },
  {
    path: '/use-point-ticket',
    name: 'use_point_ticket',
    meta: { requiresAuth: true, layout: 'default' },
    component: () =>
      import(/* webpackChunkName: "use_point_ticket" */ '../views/point/UsePointTicket.vue'),
  },
  {
    path: '/transactions/history/:card_no',
    name: 'transaction_history',
    meta: { requiresAuth: true, layout: 'default' },
    component: () =>
      import(/* webpackChunkName: "transaction" */ '../views/transaction/TransactionHistory.vue'),
  },
  {
    path: '/transactions/detail/:transaction_id',
    name: 'transaction_detail',
    meta: { requiresAuth: true, layout: 'default' },
    component: () =>
      import(/* webpackChunkName: "transaction" */ '../views/transaction/TransactionDetail.vue'),
  },
  {
    path: '/transactions/confirm/:transaction_id',
    name: 'transaction_confirm',
    meta: { requiresAuth: true, layout: 'default' },
    component: () =>
      import(/* webpackChunkName: "transaction" */ '../views/transaction/TransactionConfirm.vue'),
  },
  {
    path: '/transactions',
    name: 'transaction_list',
    component: () =>
      import(/* webpackChunkName: "transaction" */ '../views/transaction/TransactionList.vue'),
    meta: { requiresAuth: true, layout: 'default' },
  },
  {
    path: '/maintain',
    name: 'maintain',
    component: () =>
      import(/* webpackChunkName: "maintain" */ '../views/Maintain.vue'),
    meta: { requiresAuth: true, layout: 'blank' },
  },
  {
    path: '/card-image',
    name: 'card_image',
    component: () =>
      import(/* webpackChunkName: "card-reader" */ '../views/card/CaptureCardImage.vue'),
    meta: { requiresAuth: true, layout: 'blank' },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.state.userStore.token;
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (isAuthenticated) {
      window.scrollTo(0, 0);
      return next();
    }

    return next('/login');
  }
  if (to.name === 'login' && isAuthenticated) {
    return next('/');
  }
  window.scrollTo(0, 0);
  next();
});

router.onError(error => {
  if (/loading chunk \d* failed./i.test(error.message) && navigator.onLine) {
    window.location.reload();
  }
});

export default router;
